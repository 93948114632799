

import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../../../config";
import Axios from "axios";
import Modal from "vue-js-modal";
import { authHeader } from "../../../../services/auth";
@Component
export default class EmployeeDetail extends Vue {
//   public organisationInfo() {
	
//     try {
//       this.$router.push({
//         path: "/organisation-info",
//         query: { type: "edit" },
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   }
// public organisationStartDate:any = null;
public timesheetData(){
  localStorage.removeItem("filterData")
  this.$router.push('/reportee-timesheet-list')
}

public async getOrganisationInfo() {
    try {
      let response = await Axios.get(BASE_API_URL + "employee/getOrganisationInfo", {
        headers: authHeader(),
      });
      this.$store.state.sessionObject.orgStartDate = response.data.data
        ? response.data.data.startedOn
        : null;
    } catch (error) {
      console.log(error);
    }
  }
async beforeMount(){
  localStorage.removeItem("filters")
  await this.getOrganisationInfo()
}

  mounted() {}
}
